import React from 'react'
import { Link, navigate } from 'gatsby'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import Layout from '../components/shared/layout'
import SEO from '../components/shared/seo'
import { FetchItem, UpdateItem } from '../utils/list.service'
import Container from '../components/shared/Container'
import { isBrowser, isAuthenticated } from '../utils/auth.service'
import { getUserInfo } from '../utils/user.service'

class EditItemPage extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      user: getUserInfo(), 
      loading: false,
      makeItem: null,
      updatedItem: null, 
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // Get the item after component mount 
  componentDidMount() {
    this.fetchItem(this.fetchParam(this.props.location.pathname));
  }

  canEdit = () => {
    return (
      this.state.makeItem && 
      this.state.makeItem.user && 
      (this.state.user.id === this.state.makeItem.user.id))
  }

  // Fetch url param from location path 
  fetchParam = (pathname) => {
    return pathname.split('/').filter(Boolean).pop()
  }

  /**
   * Fetch toMake item by slug 
   **/
  fetchItem = async (itemSlug) => {
    this.setState({ loading: true })

    try {
      const makeItem = await FetchItem(itemSlug); 
      // validate edit access 
      if (
        makeItem && 
        makeItem.user && 
        makeItem.user.id === this.state.user.id
      ) {
        console.log('Access granted')
      } else {
        console.log('no access')
        navigate(`/item/${makeItem.slug}`);
        return;
      }
      this.setState({
        makeItem: makeItem,
        updatedItem: {
          id: makeItem.id,
          title: makeItem.title,
          description: makeItem.description
        }
      })
    } catch(err) {
      console.warn(err)
      this.setState({err: err})
    }
    
    this.setState({ loading: false })
  }

  onChange = (evt) => {
    evt.preventDefault(); 
    const name = evt.target.name; 
    let value = evt.target.value
    
    if (name==='title') { value = value.substr(0,100) }
    if (name==='description') { value = value.substr(0,500) }

    this.setState(prevState => ({
      updatedItem: {
        ...prevState.updatedItem,
        [name]: value
      }
    }))
  }

  handleSubmit = async (evt) => {
    evt.preventDefault(); 
    try {
      const result = await UpdateItem(this.state.makeItem.id, this.state.updatedItem);
      navigate(`/item/${this.state.makeItem.slug}`)
      return;
    } catch(err) {
      console.warn(err);
    }
  }

  setTitle = () => {
    return this.state.makeItem ? this.state.makeItem.title : ''; 
  }

  render() {
    const { location } = this.props; 
    const { makeItem } = this.state; 
    if (!makeItem) { return null }

    if (!location) { 
      navigate('/'); 
      return null;
    }
    
    const formData = {
      title: this.state.updatedItem.title || '',
      description: this.state.updatedItem.description || ''
    }
    
    return (
      <Layout>

        <SEO title={this.setTitle()} />
        
        <Container>
          <PageHeader>
            <h3>Edit make item</h3>
            <h1>{ makeItem.title }</h1>
            <Link to={`/item/${makeItem.slug}`}>Terug</Link>
          </PageHeader>
          
          { this.state.loading && (<p>Loading item</p>)}
          { !this.state.loading && !this.state.makeItem && (
            <p>Item <em>{ this.state.makeItem.slug }</em> not found</p>
          )}
          { !this.state.loading && this.state.makeItem && (
            <form onSubmit={this.handleSubmit}>
              <FormItem>
                <Label>Title</Label>
                <Input 
                  name="title"
                  type="text" 
                  value={formData.title}
                  onChange={this.onChange}
                />
              </FormItem>
              <FormItem>
                <Label>Description</Label>
                <Textarea 
                  name="description"
                  rows="5"
                  value={formData.description}
                  onChange={this.onChange}
                />
              </FormItem>
              <FormItem>
                <SubmitBtn type="submit">Versturen</SubmitBtn>
              </FormItem>
            </form>
          )}

        </Container>
      </Layout>
    )
  }    
}

const PageHeader = styled.header`${tw`py-16 text-center`}`
const FormItem = styled.div`${tw`my-8`}`
const Label = styled.label`${tw`font-bold`}`
const Input = styled.input`
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-width: 1px;
  ${tw`w-full text-lg leading-loose my-2 p-2 rounded border-1 border-gray-600 border-solid`}
`
const Textarea = styled.textarea`
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-width: 1px;
  ${tw`w-full text-lg leading-loose my-2 p-2 rounded border-1 border-gray-600 border-solid`}
`

const SubmitBtn = styled.button`${tw`border-2 border-orange-500 border-solid bg-white p-4 text-orange-500 uppercase font-bold cursor-pointer hover:bg-orange-500 hover:text-white`}`
export default EditItemPage; 
